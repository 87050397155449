<template>
  <div>
    <section
      id="download"
      class="section section-blue jpadding jpadding-20 bg-gradient"
    >
      <div v-if="isDesktop" class="download-desktop">
        <div class="text-center">
          <h3 class="mb-0" style="color: #a4c639">
            <i class="fa fa-android"></i>
          </h3>
          <h3>
            To get started, please visit
            <span class="text-orange">get.talkii.app/android</span> on an Android
            device, or scan the QR codes below.
          </h3>
        </div>

        <div class="qr-container">
          <div class="qr-box">
            <img
              src="@/assets/vectors/qr/talkii-web-qr.svg"
              alt="Talkii QR Code"
            />
            <p><span class="fal fa-mobile-android fa-2x"></span></p>
          </div>
          <div class="qr-box">
            <img
              src="@/assets/vectors/qr/talkii-web-qr.svg"
              alt="Talkii QR Code"
            />
            <p><span class="fal fa-tablet-android fa-2x fa-rotate-90"></span></p>
          </div>
        </div>
      </div>
      <!-- <carousel
        v-if="!isDesktop"
        :per-page="1"
        :navigate-to="someLocalProperty"
        :mouse-drag="true"
      >
        <slide> -->
      <div v-if="!isDesktop" class="jcard-app-container">
        <div class="jcard jcard-main jcard-app jcard-nohover">
          <div class="jcard-content text-center">
            <div class="app-icon app-icon-lg img-sm-scale">
              <a href="" target="_blank"
                ><img
                  src="@/assets/logos/talkii-logo-gradient.png"
                  alt="Talkii Supervisor App"
              /></a>
            </div>
            <h2>Supervisor</h2>
            <p><i class="fal fa-mobile-android"></i></p>
            <p>
              <small
                >It is recommended to download this app on your
                <strong>smartphone</strong>.</small
              >
            </p>
            <p>
              <span class="badge badge-pill badge-secondary"
                >version <strong>3.1.4</strong></span
              >
            </p>
            <div class="jcard-btn-container jcard-btn-container-center">
              <a href="/apk/Talkii-3.1.4.apk" class="jbtn jbtn-sm">
                Download</a
              >
            </div>
          </div>
        </div>
        <!-- </slide> -->
        <slide>
          <div class="jcard jcard-main jcard-app jcard-nohover">
            <div class="jcard-content text-center">
              <div class="app-icon app-icon-lg app-icon-gradient img-sm-scale">
                <a href="" target="_blank"
                  ><img
                    src="@/assets/logos/talkii-logo-white.png"
                    alt="Talkii Supervisor App"
                /></a>
              </div>
              <h2>Tab</h2>
              <p><i class="fal fa-tablet-android fa-rotate-90"></i></p>
              <p>
                <small
                  >It is recommended to download this app on your
                  <strong>tablet</strong>.</small
                >
              </p>
              <p>
                <span class="badge badge-pill badge-secondary"
                  >version <strong>3.1.4</strong></span
                >
              </p>
              <div class="jcard-btn-container jcard-btn-container-center">
                <a :href="'/apk/TalkiiTab-3.1.4.apk'" download class="jbtn jbtn-sm">
                  Download</a
                >
              </div>
            </div>
          </div>
        </slide>
      </div>
      <!-- </carousel> -->
    </section>
  </div>
</template>

<script>
import { isAndroid } from 'mobile-device-detect';
// import firebase from "firebase/app";
// import C from "@/constants";
// import { Carousel, Slide } from "vue-carousel";

export default {
  name: "No Subscription",
  // components: {
  //   Carousel,
  //   Slide,
  // },
  data() {
    return {
      isDesktop: !isAndroid,
    };
  },
  methods: {
    // startBuy(type) {
    //   this.$store.commit("startAddContrat");
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/download.scss";
</style>
